.graphiql-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.graphiql-container .editorWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
}

.graphiql-container .topBarWrap {
  display: flex;
  flex-direction: row;
}

.graphiql-container .topBar {
  align-items: center;
  background: linear-gradient(#f7f7f7, #e2e2e2);
  border-bottom: 1px solid #d0d0d0;
  cursor: default;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 34px;
  overflow-y: visible;
  padding: 7px 14px 6px;
  user-select: none;
}

.graphiql-container .toolbar {
  overflow-x: visible;
  display: flex;
}

.graphiql-container .docExplorerShow,
.graphiql-container .historyShow {
  background: linear-gradient(#f7f7f7, #e2e2e2);
  border-radius: 0;
  border-bottom: 1px solid #d0d0d0;
  border-right: none;
  border-top: none;
  color: #3b5998;
  cursor: pointer;
  margin: 0;
  padding: 2px 20px 0 18px;
}

.graphiql-container .docExplorerShow {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.graphiql-container .historyShow {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 0;
}

.graphiql-container .docExplorerShow:before {
  border-left: 2px solid #3b5998;
  border-top: 2px solid #3b5998;
  content: "";
  display: inline-block;
  height: 9px;
  margin: 0 3px -1px 0;
  position: relative;
  transform: rotate(-45deg);
  width: 9px;
}

.graphiql-container .editorBar {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.graphiql-container .queryWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.graphiql-container .resultWrap {
  border-left: solid 1px #e0e0e0;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 1em;
  position: relative;
}

.graphiql-container .docExplorerWrap,
.graphiql-container .historyPaneWrap {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
}

.graphiql-container .historyPaneWrap {
  min-width: 230px;
  z-index: 5;
}

.graphiql-container .docExplorerResizer {
  cursor: col-resize;
  height: 100%;
  left: -5px;
  position: absolute;
  top: 0;
  width: 10px;
  z-index: 10;
}

.graphiql-container .docExplorerHide {
  cursor: pointer;
  margin: -7px -8px -6px 0;
  padding: 18px 16px 15px 12px;
  background: 0;
  border: 0;
  line-height: 14px;
}

.graphiql-container div .query-editor {
  flex: 1;
  position: relative;
}

.graphiql-container .secondary-editor {
  display: flex;
  flex-direction: column;
  height: 30px;
  position: relative;
}

.graphiql-container .secondary-editor-title {
  background: #eeeeee;
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px solid #e0e0e0;
  color: #777;
  font-variant: small-caps;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 14px;
  padding: 6px 0 8px 43px;
  text-transform: lowercase;
  user-select: none;
}

.graphiql-container .codemirrorWrap {
  flex: 1;
  height: 100%;
  position: relative;
}

.graphiql-container .result-window {
  flex: 1;
  height: 100%;
  position: relative;
}

.graphiql-container .footer {
  background: #f6f7f8;
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  margin-left: 12px;
  position: relative;
}

.graphiql-container .footer:before {
  background: #eeeeee;
  bottom: 0;
  content: " ";
  left: -13px;
  position: absolute;
  top: -1px;
  width: 12px;
}

/* No `.graphiql-container` here so themes can overwrite */
.result-window .CodeMirror {
  background: #f6f7f8;
}

.graphiql-container .result-window .CodeMirror-gutters {
  background-color: #eeeeee;
  border-color: #e0e0e0;
  cursor: col-resize;
}

.graphiql-container .result-window .CodeMirror-foldgutter,
.graphiql-container .result-window .CodeMirror-foldgutter-open:after,
.graphiql-container .result-window .CodeMirror-foldgutter-folded:after {
  padding-left: 3px;
}

.graphiql-container .toolbar-button {
  background: #fdfdfd;
  background: linear-gradient(#f9f9f9, #ececec);
  border: 0;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2),
    0 1px 0 rgba(255, 255, 255, 0.7), inset 0 1px #fff;
  color: #555;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  padding: 3px 11px 5px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}

.graphiql-container .toolbar-button:active {
  background: linear-gradient(#ececec, #d5d5d5);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.7),
    inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 1px 1px rgba(0, 0, 0, 0.12),
    inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.graphiql-container .toolbar-button.error {
  background: linear-gradient(#fdf3f3, #e6d6d7);
  color: #b00;
}

.graphiql-container .toolbar-button-group {
  margin: 0 5px;
  white-space: nowrap;
}

.graphiql-container .toolbar-button-group > * {
  margin: 0;
}

.graphiql-container .toolbar-button-group > *:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.graphiql-container .toolbar-button-group > *:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.graphiql-container .execute-button-wrap {
  height: 34px;
  margin: 0 14px 0 28px;
  position: relative;
}

.graphiql-container .execute-button {
  background: linear-gradient(#fdfdfd, #d2d3d6);
  border-radius: 17px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 #fff;
  cursor: pointer;
  fill: #444;
  height: 34px;
  margin: 0;
  padding: 0;
  width: 34px;
}

.graphiql-container .execute-button svg {
  pointer-events: none;
}

.graphiql-container .execute-button:active {
  background: linear-gradient(#e6e6e6, #c3c3c3);
  box-shadow: 0 1px 0 #fff, inset 0 0 2px rgba(0, 0, 0, 0.2),
    inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.graphiql-container .toolbar-menu,
.graphiql-container .toolbar-select {
  position: relative;
}

.graphiql-container .execute-options,
.graphiql-container .toolbar-menu-items,
.graphiql-container .toolbar-select-options {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.25);
  margin: 0;
  padding: 6px 0;
  position: absolute;
  z-index: 100;
}

.graphiql-container .execute-options {
  min-width: 100px;
  top: 37px;
  left: -1px;
}

.graphiql-container .toolbar-menu-items {
  left: 1px;
  margin-top: -1px;
  min-width: 110%;
  top: 100%;
  visibility: hidden;
}

.graphiql-container .toolbar-menu-items.open {
  visibility: visible;
}

.graphiql-container .toolbar-select-options {
  left: 0;
  min-width: 100%;
  top: -5px;
  visibility: hidden;
}

.graphiql-container .toolbar-select-options.open {
  visibility: visible;
}

.graphiql-container .execute-options > li,
.graphiql-container .toolbar-menu-items > li,
.graphiql-container .toolbar-select-options > li {
  cursor: pointer;
  display: block;
  margin: none;
  max-width: 300px;
  overflow: hidden;
  padding: 2px 20px 4px 11px;
  white-space: nowrap;
}

.graphiql-container .execute-options > li.selected,
.graphiql-container .toolbar-menu-items > li.hover,
.graphiql-container .toolbar-menu-items > li:active,
.graphiql-container .toolbar-menu-items > li:hover,
.graphiql-container .toolbar-select-options > li.hover,
.graphiql-container .toolbar-select-options > li:active,
.graphiql-container .toolbar-select-options > li:hover,
.graphiql-container .history-contents > li:hover,
.graphiql-container .history-contents > li:active {
  background: #e10098;
  color: #fff;
}

.graphiql-container .toolbar-select-options > li > svg {
  display: inline;
  fill: #666;
  margin: 0 -6px 0 6px;
  pointer-events: none;
  vertical-align: middle;
}

.graphiql-container .toolbar-select-options > li.hover > svg,
.graphiql-container .toolbar-select-options > li:active > svg,
.graphiql-container .toolbar-select-options > li:hover > svg {
  fill: #fff;
}

.graphiql-container .doc-explorer-title-bar,
.graphiql-container .history-title-bar {
  cursor: default;
  display: flex;
  height: 47px;
  line-height: 14px;
  padding: 8px 8px 5px;
  position: relative;
  user-select: none;
}

.graphiql-container .doc-explorer-title,
.graphiql-container .history-title {
  flex: 1;
  font-weight: bold;
  overflow-x: hidden;
  padding: 10px 0 10px 10px;
  text-align: center;
  text-overflow: ellipsis;
  user-select: text;
  white-space: nowrap;
}

.graphiql-container .doc-explorer-back {
  color: #61affe;
  cursor: pointer;
  margin: -7px 0 -6px -8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: 0;
  border: 0;
  line-height: 14px;
  outline: none;
}

.doc-explorer-narrow .doc-explorer-back {
  width: 0;
}

.graphiql-container .doc-explorer-back:hover {
  text-decoration: underline;
}

.graphiql-container .doc-explorer-back:before {
  border-left: 2px solid #61affe;
  border-top: 2px solid #61affe;
  content: "";
  display: inline-block;
  height: 9px;
  margin: 0 3px -1px 0;
  position: relative;
  transform: rotate(-45deg);
  width: 9px;
}

.graphiql-container .doc-explorer-rhs {
  position: relative;
}

.graphiql-container .doc-explorer-contents,
.graphiql-container .history-contents {
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding-bottom: 20px;
}

.graphiql-container .doc-explorer-contents {
  min-width: 300px;
  margin-top: 10px;
}

.graphiql-container .doc-type-description p:first-child,
.graphiql-container .doc-type-description blockquote:first-child {
  margin-top: 0;
}

.graphiql-container .doc-explorer-contents a {
  cursor: pointer;
  text-decoration: none;
}
.graphiql-container .doc-explorer-contents .doc-type-description a {
  color: #61affe;
}

.graphiql-container .doc-explorer-contents a:hover {
  text-decoration: underline;
}

.graphiql-container .doc-value-description > :first-child {
  margin-top: 4px;
}

.graphiql-container .doc-value-description > :last-child {
  margin-bottom: 4px;
}

.graphiql-container .doc-type-description code,
.graphiql-container .doc-type-description pre,
.graphiql-container .doc-category code,
.graphiql-container .doc-category pre {
  --saf-0: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  line-height: 1.50001;
  font-variant-ligatures: none;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  -webkit-tab-size: 4;
  -moz-tab-size: 4;
  tab-size: 4;
}

.graphiql-container .doc-type-description code,
.graphiql-container .doc-category code {
  padding: 2px 3px 3px;
  border: 1px solid var(--saf-0);
  border-radius: 3px;
  background-color: rgba(var(--sk_foreground_min, 29, 28, 29), 0.4);
  color: #49cc90;
}

.graphiql-container .doc-category {
  margin: 20px 0;
}

.graphiql-container .doc-category-title {
  color: #777;
  cursor: default;
  font-variant: small-caps;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px 0;
  user-select: none;
}

.graphiql-container .doc-category-item {
  margin: 12px 0;
  color: #555;
}

.graphiql-container .keyword {
  color: #b11a04;
}

.graphiql-container .type-name {
  color: #49cc90;
}

.graphiql-container .field-name {
  color: #61affe;
}

.graphiql-container .field-short-description {
  color: #999;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.graphiql-container .enum-value {
  color: #0b7fc7;
}

.graphiql-container .arg-name {
  color: #6c46ff;
}

.graphiql-container .arg {
  display: block;
  margin-left: 1em;
}

.graphiql-container .arg:first-child:last-child,
.graphiql-container .arg:first-child:nth-last-child(2),
.graphiql-container .arg:first-child:nth-last-child(2) ~ .arg {
  display: inherit;
  margin: inherit;
}

.graphiql-container .arg:first-child:nth-last-child(2):after {
  content: ", ";
}

.graphiql-container .arg-default-value {
  color: #43a047;
}

.graphiql-container .doc-deprecation {
  background: #fffae8;
  box-shadow: inset 0 0 1px #bfb063;
  color: #867f70;
  line-height: 16px;
  margin: 8px -8px;
  max-height: 80px;
  overflow: hidden;
  padding: 8px;
  border-radius: 3px;
}

.graphiql-container .doc-deprecation:before {
  content: "Deprecated:";
  color: #c79b2e;
  cursor: default;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  padding-bottom: 5px;
  text-transform: uppercase;
  user-select: none;
}

.graphiql-container .doc-deprecation > :first-child {
  margin-top: 0;
}

.graphiql-container .doc-deprecation > :last-child {
  margin-bottom: 0;
}

.graphiql-container .show-btn {
  -webkit-appearance: initial;
  display: block;
  border-radius: 3px;
  border: solid 1px #ccc;
  text-align: center;
  padding: 8px 12px 10px;
  width: 100%;
  box-sizing: border-box;
  background: #fbfcfc;
  color: #555;
  cursor: pointer;
}

.graphiql-container .search-box {
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 10px;
}

.graphiql-container .search-box-icon {
  cursor: text;
  display: block;
  font-size: 24px;
  position: absolute;
  top: 4px;
  left: 8px;
  transform: rotate(-45deg);
  user-select: none;
}

.graphiql-container .search-box .search-box-clear {
  background-color: #b9b9b9;
  border-radius: 12px;
  cursor: pointer;
  padding: 1px 5px 2px;
  position: absolute;
  right: 8px;
  top: 10px;
  user-select: none;
  border: 0;
  outline: none;
}

.graphiql-container .search-box .search-box-clear:hover {
  background-color: #d0d0d0;
}

.graphiql-container .search-box > input {
  border: none;
  box-sizing: border-box;
  outline: none;
  padding: 8px 36px;
  width: 100%;
  height: 2.5rem;
}

.graphiql-container .error-container {
  font-weight: bold;
  left: 0;
  letter-spacing: 1px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(0, -50%);
}
