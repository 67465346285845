@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

body {
  margin: 0;
  color: #c3c5d1;
  background: #262936 !important;
  overflow: hidden;
}

* {
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.react-toast-notifications__container {
  left: 20px !important;
  bottom: 15px !important;
  overflow: hidden !important;
}

.react-toast-notifications__toast--error
  .react-toast-notifications__toast__icon-wrapper {
  background-color: #db3f3f !important;
}

.react-toast-notifications__toast--success
  .react-toast-notifications__toast__icon-wrapper {
  background-color: #2196f3 !important;
}

.react-toast-notifications__toast,
.react-toast-notifications__toast__content {
  color: #c3c5d1 !important;
  background: #313648 !important;
  overflow: hidden !important;
}
