.swagger-ui {
  width: 100%;
}
.swagger-ui * {
  color: #c3c5d1 !important;
}
.swagger-ui .opblock-summary-method {
  color: #fff !important;
}

.swagger-ui .opblock-section-header * {
  color: #333 !important;
}

.arrow {
  fill: #c3c5d1;
}

.swagger-ui .scheme-container {
  background-color: transparent !important;
}

.swagger-ui .dialog-ux .modal-ux * {
  color: #353535 !important;
}
